import styles from '@app/styles/components/TagFree.module.sass';
import {ISGLOBAL} from '@app/constants';
import React from 'react';

/**
 * @param {object} props
 * @param {object} props.styles
 */
export default function TagFree(props) {
  return (
    <>
      {ISGLOBAL === false && (
        <div className={styles.wrap} style={props.styles}>
          <span className={styles.title}>GRATIS</span>
        </div>
      )}
    </>
  );
}
